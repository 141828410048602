<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table 
                    striped
                    small
                    id="myTables"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    :items="document"
                    :fields="fields"
                    head-row-variant="primary"
                    >
                    <template #cell(checkbox)="row">
                      <b-form-checkbox
                      v-model="row.detailsShowing"
                      plain
                      class="vs-checkbox-con"
                      @change="row.toggleDetails"
                      >
                      </b-form-checkbox>
                  </template> 
                  <template #cell(category)="data">
                    <span v-if="data.item.category == 'payment'">
                        Пополнения
                    </span>
                    <span v-if="data.item.category == 'charge'">
                        Вычитания
                    </span>
                    </template> 
                  <template #cell(status)=data>
                    <span v-if="data.item.status === 'SUCCESS'">
                        Документ проведён
                    </span>
                  </template>
                </b-table>
            </b-card>
        </b-col>
        </b-row>
    </div>
</template>

<script>
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/css/bootstrap.css'

export default {
    data() {
        return {
            document_id: this.$route.params,
            document: [],
            fields: [
            { key: 'checkbox', label: '', thStyle: {width: '30px'}},
            { key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
            { key: 'category', label: 'Котегория', sortable: true, thStyle: {width: '70px'}},
            { key: 'promocode_usage_id', label: 'ID использования промокода', sortable: true, thStyle: {width: '70px'}},
            { key: 'status', label: 'Тип действия', sortable: true, thStyle: {width: '150px'}},
            { key: 'amount', label: 'Цена', sortable: true, thStyle: {width: '150px'}},
            { key: 'sum_for', label: 'Сумма до', sortable: true, thStyle: {width: '150px'} },
            { key: 'sum_after', label: 'Сумма после', sortable: true, thStyle: {width: '150px'} },
            { key: 'order_id', label: 'ID заказа', sortable: true, thStyle: {width: '150px'} },
            { key: 'user', label: 'Пользователь', sortable: true, thStyle: {width: '150px'} },
            { key: 'type_doc', label: 'Тип документа', sortable: true, thStyle: {width: '150px'} },
            { key: 'comment', label: 'Коментарий', sortable: true, thStyle: {width: '150px'} },
            { key: 'account_id', label: 'ID аккаунта', sortable: true, thStyle: {width: '150px'} },
            { key: 'created_at', label: 'Дата создания', sortable: true, thStyle: {width: '150px'} },
            { key: 'updated_at', label: 'Дата окончания', sortable: true, thStyle: {width: '150px'} },
          ]
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.resizeable(),
        this.getDocumentInfo()
      
    },
    methods: {
        resizeable(){
            var thElm;
            var startOffset;
            Array.prototype.forEach.call(
            document.querySelectorAll("table th"),
            function (th) {
                th.style.position = 'relative';
                var grip = document.createElement('div');
                grip.innerHTML = "&nbsp;";
                grip.style.top = 0;
                grip.style.right = 0;
                grip.style.bottom = 0;
                grip.style.width = '5px';
                grip.style.position = 'absolute';
                grip.style.cursor = 'col-resize';
                grip.addEventListener('mousedown', function (e) {
                    thElm = th;
                    startOffset = th.offsetWidth - e.pageX;
                });
                th.appendChild(grip);
            });
            document.addEventListener('mousemove', function (e) {
                if (thElm) {
                    thElm.style.width = startOffset + e.pageX + 'px';
                }
            });
            document.addEventListener('mouseup', function () {
                thElm = undefined;
            });
        },
        getDocumentInfo() {
            console.log(this.document_id);
            this.$http
            .post(`promo-code/document`, this.document_id)
            .then(res => {
                console.log(res.data);
                this.document = [res.data]
                this.$store.commit('pageData/setdataCount', this.document.length)
            }).catch(err => {
                console.log(this.document_id, 123123);
            })
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
</style>